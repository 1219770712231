export interface TicketCreateModel {
    ClientId?: string;
    ClientSiteId: string;
    CandidateId: string;
    RoleId: string;
    IssueDate: Date;
}

export interface Ticket {
    Id: number;
    Type: number;
    ClientId: string;
    ClientSiteId: string;
    CandidateId: string;
    RoleId: string;
    UserId: string;
    IssueDate: Date;
    CreatedDate: Date;
    ResolvedDate: Date;
    Status: number;
    Notes: string;
}