import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService, HeaderService, MenuService, LearnService} from "@app/services";
import { Permission, ClientContactSummary } from "../../interfaces/user";
import { delay } from "rxjs/operators";
import { ClientSummary } from "@app/interfaces";

@Component({
  selector: "app-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.scss"]
})
export class MobileMenuComponent implements OnInit {
  joinCommunityLink = "";
  userToken: string;
  userInfo: ClientSummary;
  hasContentViewingPermission = false;
  hasJobSeekingPermission = false;
  hasJobBookingPermission = false;
  hasJobCheckingInPermission = false;
  hasJobSigningOffPermission = false;
  hasAccountsPermission = false;
  hasTalentPoolUsePermission = false;
  hasClientAdministratorPermission = false;
  showOpogoHub = false;
  defaultCandidatePhotoUrl: boolean = true;
  userName: string = "";
  showSettingsMenu: boolean = false;

  constructor(
    public router: Router,
    private headerService: HeaderService,
    public menuService: MenuService,
    private auth: AuthService,
    private learnService: LearnService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showOpogoHub = event.url.indexOf("/hub") !== -1;
      }
    });
  }

  ngOnInit() {
    this.headerService.headerObject.pipe(delay(0)).subscribe(header => {
      this.userInfo = header.userInfo;

      this.defaultCandidatePhotoUrl = !this.userInfo || (this.userInfo &&
      (this.userInfo.ProfileImageUrl === null || this.userInfo.ProfileImageUrl === "" ||
      this.userInfo.ProfileImageUrl.includes('default-member-photo.png')));

      if (this.userInfo) {
        this.userName = this.userInfo.FirstName + ' ' + this.userInfo.LastName;
      }

      if (this.userInfo && this.userInfo.Permissions) {
        this.hasContentViewingPermission =
          this.userInfo.Permissions.indexOf(Permission.ContentViewing) !== -1;
        this.hasJobSeekingPermission =
          this.userInfo.Permissions.indexOf(Permission.JobSeeking) !== -1;
        this.hasJobBookingPermission =
          this.userInfo.Permissions.indexOf(Permission.JobBooking) !== -1;
        this.hasJobCheckingInPermission =
          this.userInfo.Permissions.indexOf(Permission.JobCheckingIn) !== -1;
        this.hasJobSigningOffPermission =
          this.userInfo.Permissions.indexOf(Permission.JobSigningOff) !== -1;
        this.hasAccountsPermission =
          this.userInfo.Permissions.indexOf(Permission.Invoice) !== -1;
        this.hasTalentPoolUsePermission =
          this.userInfo.Permissions.indexOf(Permission.TalentPool) !== -1;
        this.hasClientAdministratorPermission =
          this.userInfo.Permissions.indexOf(Permission.ClientAdministrator) !==
          -1;
      }
    });

    this.userToken = this.auth.token;
    this.joinCommunityLink = this.learnService.getLearnUrl(this.userToken);
  }

  showHideRightMenu() {
    this.menuService.hideRight();
    this.showSettingsMenu = false;
  }

  showHideSettingsMenu() {
    this.showSettingsMenu = !this.showSettingsMenu;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(["login"]);
    this.showHideRightMenu();
  }

}
