import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ClientDataStoreService } from '@app/services/client-data-store.service';
import { ClientSelectDropdown, ClientTree } from '../client-select-dropdown';
import { filter } from 'rxjs';

@Component({
  selector: 'app-client-select-dropdown-desktop',
  standalone: true,
  imports: [
    CommonModule
  ],
  providers: [],
  templateUrl: './client-select-dropdown-desktop.component.html',
  styleUrl: './client-select-dropdown-desktop.component.scss'
})
export class ClientSelectDropdownDesktopComponent extends ClientSelectDropdown implements OnDestroy {

  constructor(clientStore: ClientDataStoreService) {
    super(clientStore);

    this.subscriptions$.push(
      clientStore.clientTree$
      .pipe(filter(client => !!client))
      .subscribe(client => {
        client.Expanded = true;
        
        this.mainClient = client;
        this.selectedClient = client;
        
        this.subscriptions$.push(clientStore.clientSummary$.subscribe(clientSummary => {
          if (clientSummary && clientSummary.ClientId !== this.selectedClient.Id) {
            this.selectedClient = this.getClientFromTreeById(this.mainClient, clientSummary.ClientId);
          }
        }));
      }))
  }

  onClientClicked(client: ClientTree) {
    if (client.Id === this.selectedClient.Id || client.Id === this.mainClient.Id) {
      this.toggleDropdown();
      
      return;
    }
    
    if (client.Clients.length > 0) {
      this.toggleClientChildren(client);
      return;
    }
    
    this.selectClient(client);
    this.toggleDropdown();
  }

  toggleDropdown() {
    this.clientDropdownExpanded = !this.clientDropdownExpanded;
  }

  ngOnDestroy(): void {
    this.subscriptions$.forEach(s => s.unsubscribe());
  }
}
