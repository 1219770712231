@if (mainClient && mainClient.Clients.length > 0) {
  <div class="client-select-dropdown-mobile row m-0" (click)="openClientSelectModal(ClientSelectModal)">
    <div class="client-name col-10 py-3 px-4">
      <span>{{ selectedClient.Name }}</span>
    </div>
    <div class="dropdown-toggle-btn col-2">
      <i class="m-icon va-m ro md expand px-2">expand_more</i>
    </div>
  </div>

  <ng-template #ClientSelectModal>
    <div class="modal-header text-center mt-3">
      <i class="m-icon va-m ro" (click)="closeClientSelectModal()">close</i>
      <span class="header-title">Select school</span>
    </div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="MainClient; context: { clientItem: mainClient }"></ng-container>
    </div>
    @if (selectedClient.Id !== checkedClientId) {
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="onSwitchAccountClicked()">Switch account</button>
      </div>
    }
  </ng-template>

  <ng-template #MainClient let-clientItem="clientItem">
    <div class="client-sub-group">
      <div class="parent-client d-flex" (click)="onClientClicked(clientItem)">
        <div class="client-expand-arrow-container">
          <div class="client-expand-arrow" 
            [ngClass]="{ 'expanded': clientItem.Expanded, 'collapsed': !clientItem.Expanded }">
            @if (clientItem.Expanded) {
              <i class="m-icon va-m ro expanded">expand_more</i>
            }
            @else {
              <i class="m-icon va-m ro collapsed">chevron_right</i>
            }
          </div>
        </div>
        <div class="name-container pr-4">
          <strong class="client-name">{{ clientItem.Name }}</strong>
        </div>
      </div>
      @if (clientItem.Expanded) {
        @for (childClient of clientItem.Clients; track childClient) {
          <div class="child-client"> 
            <ng-container *ngTemplateOutlet="ClientTree; context: { clientItem: childClient }"></ng-container>
          </div>
        }
      }
    </div>
  </ng-template>

  <ng-template #ClientTree let-clientItem="clientItem">
    @if (clientItem.Clients.length > 0) {
      <div class="client-data" (click)="toggleClientChildren(clientItem)">
        <div class="client-expand-arrow-container">
          <div class="client-expand-arrow"
            [ngClass]="{ 'expanded': clientItem.Expanded, 'collapsed': !clientItem.Expanded }">
            @if (clientItem.Expanded) {
              <i class="m-icon va-m ro expanded">expand_more</i>
            }
            @else {
              <i class="m-icon va-m ro collapsed">chevron_right</i>
            }
          </div>
        </div>
        <div class="name-container pr-4">
          <strong class="client-name">{{ clientItem.Name }} {{ '(' + clientItem.Clients.length + ')' }}</strong>
        </div>
      </div>
      @if (clientItem.Expanded) {
        <div>
          @for (childClient of clientItem.Clients; track childClient) {
            <div class="child-client"> 
              <ng-container *ngTemplateOutlet="ClientTree; context: { clientItem: childClient }"></ng-container>
            </div>
          }
        </div>
      }
    }
    @else {
      <div class="client-data" (click)="onClientClicked(clientItem)">
        <div class="logo-container">
          @if (clientItem.LogoIconImageUrl) {
            <img class="client-logo" [src]="clientItem.LogoIconImageUrl">
          }
          @else {
            <div class="client-logo-placeholder"></div>
          }
        </div>
        <div class="client-option custom-control custom-radio pl-0">
          <input class="custom-control-input" [id]="clientItem.Id" type="radio" [checked]="clientItem.Id === checkedClientId">
          <label class="custom-control-label name-container">
            <span class="client-name">{{ clientItem.Name }}</span>
          </label>
        </div>
      </div>
    }
  </ng-template>
}