export interface Review {
  Id?: string;
  CandidateId: string;
  ClientId?: string;
  UserId?: string;
  CreatedDateTime?: Date;
  HireAgain? : boolean;
  Punctuality? : number;
  Professionalism? : number;
  Communication? : number;
  Testimonial? : string;
  TestimonialVisible? : boolean;
  N_Late? : boolean;
  N_ClassMan? : boolean;
  N_Comm? : boolean;
  N_Professional? : boolean;
  N_Cultural? : boolean;
  N_Other? : boolean;
  N_Feedback? : string;
}