import { Directive } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { ClientDataStoreService } from '@app/services/client-data-store.service';

export class ClientTree {
  Id: string;
  Name: string;
  LogoIconImageUrl: string;
  Clients: ClientTree[];
  Expanded: boolean;
  
  constructor() {
    this.Expanded = false;
  }
  
  public deserialize(input: any) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }
    
    Object.keys(input)
    .forEach(key => {
      if (typeof input[key] !== 'undefined') {
        this[key] = input[key];
      }
    });
    
    return this;
  }
}

@Directive()
export class ClientSelectDropdown {
  mainClient: ClientTree;
  selectedClient: ClientTree;
  
  clientDropdownExpanded: boolean = false;
  
  selectClientSubscription: Subscription;
  subscriptions$: Subscription[] = [];
  
  constructor(private clientStore: ClientDataStoreService) {}
  
  
  selectClient(client: ClientTree) {
    this.selectedClient = client;
    localStorage.setItem('selectedClient', client.Id);
    
    this.selectClientSubscription?.unsubscribe();
    this.selectClientSubscription = this.clientStore.selectClient(client.Id).subscribe();   
  }
  
  toggleClientChildren(client: ClientTree) {
    client.Expanded = !client.Expanded; 
  }
  
  getClientFromTreeById(clientTree: ClientTree, clientId, expandParent = false): ClientTree {
    if (clientTree.Id === clientId) {
      return clientTree;
    }
    
    for (let i = 0; i < clientTree.Clients.length; i++) {
      let client = this.getClientFromTreeById(clientTree.Clients[i], clientId);
      
      if (client) {
        if (expandParent) {
          clientTree.Expanded = true;
        }
        
        return client;
      }
    }

    return null;
  }
}