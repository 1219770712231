import { Injectable } from '@angular/core';
import { ClientSummary, Term } from '@app/interfaces';
import { BehaviorSubject, combineLatest, filter, map, Observable, Subject, takeUntil, tap, zip } from 'rxjs';
import { HttpService } from './http.service';
import { HttpParams } from '@angular/common/http';
import { ClientTree } from '@app/components/client-select-dropdown/client-select-dropdown';
import { JobRoleConfig } from './job-setup.service';

export class ClientData {
  ClientSummary: ClientSummary;
  Terms: Term[];
  RoleConfig: JobRoleConfig;
}

@Injectable({
  providedIn: 'root'
})
export class ClientDataStoreService {
  //private _clientLoaded = new BehaviorSubject<string>(null);
  private readonly _clientSummary = new BehaviorSubject<ClientSummary>(null);
  private readonly _clientTree = new BehaviorSubject<ClientTree>(null);
  readonly _roleConfig = new BehaviorSubject(null);
  private readonly _terms = new BehaviorSubject<Term[] | null>([]);

  public destroy$ = new Subject<void>();

  readonly clientSummary$ = this._clientSummary.asObservable().pipe(filter(x => !!x), takeUntil(this.destroy$));
  readonly clientTree$ = this._clientTree.asObservable().pipe(filter(x => !!x), takeUntil(this.destroy$));
  readonly roleConfig$ = this._roleConfig.asObservable().pipe(filter(x => !!x), takeUntil(this.destroy$));
  readonly terms$ = this._terms.asObservable().pipe(filter(x => !!x), takeUntil(this.destroy$));

  readonly clientData$ = combineLatest(this.clientSummary$, this.terms$, this.roleConfig$).pipe(
    map(x => <ClientData>{ClientSummary: x[0], Terms: x[1], RoleConfig: x[2] }),
    takeUntil(this.destroy$));

  readonly clientLoaded$ = combineLatest(
    this._clientSummary.asObservable(),
    this._roleConfig.asObservable(),
    this._terms.asObservable()
  ).pipe(
      map(x => !!x[0] && !!x[1] && !!x[2])
    );

  constructor(private http: HttpService) {}

  public selectClient(clientId: string | null): Observable<any> {
    this._clientSummary.next(null);
    this._terms.next(null);
    this._roleConfig.next(null);

    return this.getClientSummary(clientId);
  }

  public refreshClientSummary(clientId: string) {
    this._clientSummary.next(null);
    this._terms.next(null); // we clear both because it's the client summary we're refreshing
    this.getClientSummary(clientId).subscribe();
  }

  public refreshClientTerms(clientId: string) {
    this._terms.next(null); // we clear only the terms because the client isn't changing
    this.getBookingTerms(clientId).subscribe();
  }

  public getClientSummary(clientId: string | null = null): Observable<ClientSummary> {
    let url: string = '/clients/summary';

    const options = !!clientId ?
      { params: new HttpParams().set('clientId', clientId) } : {};
    
    return this.http.get(url, options)
      .pipe(tap(clientSummary => {
        if (!clientSummary) {
          throw new Error('Unable to fetch clients from the server!');
        }

        this._clientSummary.next(clientSummary);
          
        this.getBookingTerms(clientSummary.ClientId).subscribe();
      }));
  }

  public getClientTree(): Observable<ClientTree> {
    let url: string = '/client/getclientbyloggeduser';

    return this.http.get(url)
      .pipe(tap(response => {
        if (!response.IsSuccess) {
          throw new Error('Unable to fetch clients from the server!');
        }

      let clientTree = this.instantiateClientTree(response.Content);

      this._clientTree.next(clientTree);
    }), map(response => response.Content));
  }

  public clearAll() {
    this._clientSummary.next(null);
    this._clientTree.next(null);
    this._terms.next([]);
  }

  public destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public resetDestroy() {
    this.destroy$ = new Subject<void>();
  }

  private getBookingTerms(clientId: string): Observable<Term[]> {
    let url: string = `/clients/${clientId}/terms`;

    return this.http.get(url)
      .pipe(map(res => {
        let terms: Term[] = [];
        if (res) {
          res.forEach(function(term) {
            terms.push(new Term().deserialize(term))
          })
        }

        return terms;
    }), tap(res => this._terms.next(res)));
  }

  private instantiateClientTree(clientTree: ClientTree) {
    clientTree = new ClientTree().deserialize(clientTree);

    if (clientTree.Clients.length === 0)
      return clientTree;

    for (let i = 0; i < clientTree.Clients.length; i++) {
      clientTree.Clients[i] = this.instantiateClientTree(clientTree.Clients[i]);
    }

    return clientTree;
  }
}
