import { Injectable } from "@angular/core";

export enum DateFormat  {
    Time = 'Time',
    TimeSimple = 'TimeSimple',
    Date = 'Date',
    Month = 'Month',
    MonthShort = 'MonthShort',
    DateMonth = 'DateMonth',
    DateMonthShort = 'DateMonthShort',
    DateMonthShortYear = 'DateMonthShortYear',
    DayNameShort = 'DayNameShort',
    TimeDayDateShortMonth = 'TimeDayDateShortMonth',
    DateShortDayName = 'DateShortDayName',
    DayDateMonthShort = 'DayDateMonthShort',
    DayCommaDateMonthShort = 'DayCommaDateMonthShort',
    DateShortWithYear = 'DateShortWithYear',
    DateLongWithYear = 'DateLongWithYear',
    DateShortMonthYearCommaTime = 'DateShortMonthYearCommaTime',
    DateShortMonthYearDashTime = 'DateShortMonthYearDashTime'
}

export enum CustomFormat {
    AvailabilityCircleUpper = 'AvailabilityCircleUpper',
    AvailabilityCircleLower = 'AvailabilityCircleLower',
    TimesheetWeekstartUpper = 'TimesheetWeekstartUpper',
    TimesheetWeekstartLower = 'TimesheetWeekstartLower',
    TimesheetWeekdayUpper = 'TimesheetWeekdayUpper',
    TimesheetWeekdayLower = 'TimesheetWeekdayLower'
}

type DateFormats = {
    [key in DateFormat]: string;
}

type CustomFormats = {
    [key in CustomFormat]: string
}

const localizedDateFormats = <DateFormats>{
    Time: `LT`,
    DateLongWithYear: `LLL`,
    TimeSimple: $localize`hh:mm`,
    DateMonth: $localize`D MMMM`,
    DateMonthShort: $localize`D MMM`,
    DateMonthShortYear: $localize`D MMM YY`,
    DateShortDayName: $localize`D ddd`,
    DayDateMonthShort: $localize`ddd D MMM`,
    DayCommaDateMonthShort: $localize`ddd, D MMM`,
    DateShortWithYear: $localize`ddd, D MMM YYYY`,
    DateShortMonthYearCommaTime: $localize`D MMM YYYY, h:mm`,
    DateShortMonthYearDashTime: $localize`D MMM YYYY - h:mm`,
    TimeDayDateShortMonth: $localize`HH:mm ddd d MMM`
}

const customLocalizedFormats = <CustomFormats>{ 
    AvailabilityCircleUpper: $localize`:The upper date format for availabiliy circles, can be either the day of the month, or the day of the week (short):D`,
    AvailabilityCircleLower: $localize`:The lower date format for availabiliy circles, can be either the day of the month, or the day of the week (short):ddd`,
    TimesheetWeekstartUpper: $localize`:Timesheet weekstart header | The upper date format for timesheets, can be either the date or the abbreviated month:D`,
    TimesheetWeekstartLower: $localize`:Timesheet weekstart header | The lower date format for timesheets, can be either the date or the abbreviated month:MMM`,
    TimesheetWeekdayUpper: $localize`:Timesheet weekday header | The upper date format for timesheets, can be either the date or the abbreviated day of the week:D`,
    TimesheetWeekdayLower: $localize`:Timesheet weekday header | The lower date format for timesheets, can be either the date or the abbreviated day of the week:ddd`
}

@Injectable()
export class LocalizedDateFormatProvider {

    getLocalizedDateFormat(key: DateFormat) {
        if (Object.keys(localizedDateFormats).includes(key)) {
            return localizedDateFormats[key];
        }

        if (Object.keys(customLocalizedFormats).includes(key)) {
            return customLocalizedFormats[key];
        }

        return null;
    }
}