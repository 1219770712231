import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  TemplateRef,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { AuthService } from "../../services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { HeaderService } from "../../services/header.service";
import { AppTitle } from "../../interfaces/header";
import { Permission } from "../../interfaces/user";
import { CandidateService, ChatService, LearnService} from "@app/services";
import { MenuService } from "../../services/menu.service";

import { DeviceService } from "./../../services/device.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";

// image upload and crop
import { LyClasses, LyTheme2 } from "@alyle/ui";
import {
  LyImageCropper,
  ImgCropperConfig,
  ImgCropperEvent
} from "@alyle/ui/image-cropper";
import { styles } from "./crop.styles";
import { delay, filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { NotificationsService } from "@app/services/notifications.service";
import { PushNotificationsService } from "@app/services/push-notifications.service";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Label } from "@app/localization/providers/localized-text.provider";
import { ClientDataStoreService } from "@app/services/client-data-store.service";
import { ClientSummary } from "@app/interfaces";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("headerSpacer", { static: false }) spacer: ElementRef;
  @ViewChild("headerContainer", { static: false }) header: ElementRef;
  @ViewChild("cookieLawContainer", { static: false }) cookieLaw: ElementRef;
  @ViewChild('clientSelectDropdown', { read: ElementRef }) clientSelectDropdown!: ElementRef;

  localizedLabel = Label;
  analytics = getAnalytics();

  joinCommunityLink = "";
  userToken: string;

  // Cookie law and fixed header helper
  showCookieLaw = true;
  viewHeight = 0;

  // Header title, subtitle, steps count
  headerData: AppTitle = <AppTitle>{
    title: ""
  };

  isMobile = false;
  isTablet = false;
  isDesktop = false;

  hasContentViewingPermission = false;
  hasJobSeekingPermission = false;
  hasJobBookingPermission = false;
  hasJobCheckingInPermission = false;
  hasJobSigningOffPermission = false;
  hasAccountsPermission = false;
  hasInvoicePermission = false;
  hasContentCreationPermission = false;
  hasClientAdministratorPermission = false;
  hasTalentPoolUsePermission = false;
  showOpogoHub = false;
  isLatestPosts = false;
  isExperts = false;
  isExpertDetails = false;
  isContentDetails = false;
  isCourses = false;
  isCourseDetails = false;
  isEvents = false;
  isEventDetails = false;
  isManageContent = false;
  showMyAccount = false;
  userInfo: ClientSummary;
  experts: any;
  logInEventSubscription: any;
  modalRef: BsModalRef;
  noHeaderFooter: boolean;

  subscriptions: Subscription[] = [];

  // image upload and crop
  classes: LyClasses<object>;
  croppedImg: string;
  isLoaded: boolean;
  isCropped: boolean;
  cancelCrop: boolean;
  @ViewChild(LyImageCropper) imgCropper: LyImageCropper;
  scale: number;
  myConfig: ImgCropperConfig = {
    width: 202,
    height: 202,
    fill: "#ffffffa8"
  };

  isIn = false; // store state
  isTrustLevel = false;
  //offsetHeader: number = 0; // top fixed MoEngage banners

  constructor(
    private headerService: HeaderService,
    public router: Router,
    private auth: AuthService,
    private cookieService: CookieService,
    private renderer: Renderer2,
    private device: DeviceService,
    public menuService: MenuService,
    private candidateService: CandidateService,
    private modalService: BsModalService,
    private theme: LyTheme2,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private chatService: ChatService,
    private pushNotificationsService: PushNotificationsService,
    private learnService: LearnService,
    private clientStore: ClientDataStoreService
  ) {
    this.classes = theme.addStyleSheet(styles);
    const routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.showOpogoHub = event.url.includes("/hub");
        this.isExperts = event.url.includes("/hub/experts");
        this.isExpertDetails = event.url.includes("/hub/author/");
        this.isContentDetails = event.url.includes("/hub/content/");
        this.isCourses = event.url.includes("/hub/courses");
        this.isCourseDetails = event.url.includes("/hub/course/");
        this.isEvents = event.url.includes("/hub/events");
        this.isEventDetails = event.url.includes("/hub/event/");
        this.isManageContent = event.url.includes("/hub/manage-content");
        this.showMyAccount = event.url.includes("/account");

        this.isLatestPosts =
          this.showOpogoHub &&
          !this.isCourses &&
          !this.isCourseDetails &&
          !this.isExperts &&
          !this.isExpertDetails &&
          !this.isEvents &&
          !this.isEventDetails &&
          !this.isManageContent;
        this.cdr.detectChanges();
      });

    this.subscriptions.push(routerSubscription);

    this.loadExperts();

    this.logInEventSubscription = this.auth.LogInEvent.subscribe(() => {
      this.loadExperts();
    });

    this.isMobile = this.device.mobile;
    this.isTablet = this.device.tablet;
    this.isDesktop = this.device.desktop;

    this.subscriptions.push(clientStore.clientTree$
      .pipe(filter(client => !!client))
      .subscribe(client => {
        this.isTrustLevel = client.Clients.length > 0;

        if (!this.isDesktop && this.isTrustLevel) {
          setTimeout(() => {
            this.onClientSelectDropdownInit();
          }, 100);
        }
      }));
  }

  ngOnInit() {
    if (this.cookieService.check("hideCookieLaw")) {
      this.showCookieLaw = false;
    }

    if (location.pathname.indexOf("/reference") > -1) {
      this.showCookieLaw = false;
    }

    if (location.pathname.indexOf("/signup") > -1) {
      this.showCookieLaw = false;
    }

    const headerServiceSubscription = this.headerService.headerObject
      .pipe(delay(0))
      .subscribe(header => {
        this.headerData = header;
        this.userInfo = header.userInfo;

        if (this.userInfo && (window as any).Notification && (window as any).Notification.permission === "default") {
          this.pushNotificationsService.subscribeToNotifications();
        }

        if (this.userInfo && this.userInfo.Permissions) {
          this.hasContentViewingPermission =
            this.userInfo.Permissions.indexOf(Permission.ContentViewing) !== -1;
          this.hasJobSeekingPermission =
            this.userInfo.Permissions.indexOf(Permission.JobSeeking) !== -1;
          this.hasJobBookingPermission =
            this.userInfo.Permissions.indexOf(Permission.JobBooking) !== -1;
          this.hasJobCheckingInPermission =
            this.userInfo.Permissions.indexOf(Permission.JobCheckingIn) !== -1;
          this.hasJobSigningOffPermission =
            this.userInfo.Permissions.indexOf(Permission.JobSigningOff) !== -1;
          this.hasAccountsPermission =
            this.userInfo.Permissions.indexOf(Permission.Invoice) !== -1 ||
            this.userInfo.Permissions.indexOf(
              Permission.ClientAdministrator
            ) !== -1;
          this.hasInvoicePermission =
            this.userInfo.Permissions.indexOf(Permission.Invoice) !== -1;
          this.hasContentCreationPermission =
            this.userInfo.Permissions.indexOf(Permission.ContentCreation) !==
              -1 && !this.isIE();
          this.hasClientAdministratorPermission =
            this.userInfo.Permissions.indexOf(
              Permission.ClientAdministrator
            ) !== -1;
          this.hasTalentPoolUsePermission =
            this.userInfo.Permissions.indexOf(Permission.TalentPool) !== -1;
        }
      });

    this.subscriptions.push(headerServiceSubscription);

    this.noHeaderAndFooter();
    //this.onDisplayMoEngageBanner()

    this.userToken = this.auth.token;
    this.joinCommunityLink = this.learnService.getLearnUrl(this.userToken);
  }

  ngAfterViewInit(): void {
    if (this.spacer && this.header) {
      (this.spacer.nativeElement as HTMLElement).style.height = `${(this.header.nativeElement as HTMLElement).offsetHeight}px`;
    }
  }

  isIE() {
    return this.device.browser.toUpperCase() === "IE";
  }

  onClientSelectDropdownInit() {
    const clientSelectDropdownHeight = this.clientSelectDropdown.nativeElement.offsetHeight;
    const spacerHeight = this.spacer.nativeElement.offsetHeight;

    const newSpacerHeight = clientSelectDropdownHeight + spacerHeight;

    this.renderer.setStyle(this.spacer.nativeElement, 'height', `${newSpacerHeight}px`);
  }

  hideCookieLaw(): void {
    this.viewHeight =
      this.header.nativeElement.offsetHeight -
      (this.cookieLaw !== undefined
        ? this.cookieLaw.nativeElement.offsetHeight
        : 0);
    this.renderer.setStyle(
      this.spacer.nativeElement,
      "height",
      this.viewHeight + "px"
    );
    this.showCookieLaw = false;
    this.cookieService.set("hideCookieLaw", "true", new Date(2099, 1, 1));
    setTimeout(() => {
      this.headerService.headerChanged();
    }, 200);
  }

  isLoggedIn(): boolean {
    return (
      this.auth.isLoggedIn() &&
      location.pathname.indexOf("/reference") === -1 &&
      location.pathname.indexOf("/set-password") === -1
    );
  }

  noHeaderAndFooter() {
    if (
      location.pathname.includes("/signup") ||
      location.pathname.includes("/confirmemail") ||
      location.pathname.includes("/embeddedsignin") ||
      location.pathname.includes("/embeddedsigninpanel") ||
      location.pathname.includes("/forgotten-password") ||
      location.pathname.includes("/signedin") ||
      location.pathname.includes("/signout")
    ) {
      this.noHeaderFooter = true;
    }
  }

  logout() {
    this.router.navigate(["login"]);
    this.auth.logout();
    this.toggleState();
  }

  toggleState() {
    this.isIn = !this.isIn;
  }

  showHideRightMenu() {
    this.menuService.hideRight();
  }

  errorPartnerLogoHandler(event) {
    this.userInfo.PartnerLogoUrl = null;
  }

  loadExperts() {
    if (this.isLoggedIn()) {
      this.candidateService.getExperts().subscribe(
        result => {
          if (result) {
            this.experts = result;
            this.experts.forEach(expert => {
              expert.Name = `${expert.FirstName.trim()} ${expert.LastName.trim()}`;
            });
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  // image upload and crop
  openModal(template: TemplateRef<any>, userInfo: any) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {
          backdrop: "static",
          keyboard: false,
          class: "change-profile-image-modal"
        }
      )
    );
    this.modalRef.content = {};
    this.modalRef.content.userImage = userInfo.ProfileImageUrl;
    this.cancelCrop = false;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  cancelCropping() {
    this.cancelCrop = !this.cancelCrop;
  }

  onCrop(e: ImgCropperEvent) {
    this.croppedImg = e.dataURL;
    this.isCropped = true;
    this.candidateService.changeProfileImage(e.dataURL).subscribe(
      result => {
        this.userInfo.ProfileImageUrl = result;
      },
      error => {
        console.log(error);
      }
    );
  }

  goToHome(){
    this.router.navigate(["/add-job"]);
  }

  onloaded(e: ImgCropperEvent) {
    this.isLoaded = true;
  }

  // refresh profile image
  public getLinkPicture() {
    if (this.userInfo && this.userInfo.ProfileImageUrl) {
      return this.userInfo.ProfileImageUrl;
    }

    return null;
  }

  getBadgeCount() {
    let count: number = this.notificationsService.notifications.length;

    if (count === 0) {
      return null;
    }

    return count > 9 ? "9+" : `${count}`;
  }

  getChatBadgeCount() {
    let count: number = this.chatService.unreadMessages;

    if (count === 0) {
      return null;
    }

    return count > 9 ? "9+" : `${count}`;
  }

  showChatPopup() {
    this.chatService.ShowChatPopup.emit();
  }

  logEventForLogo() {
    logEvent(this.analytics, 'click_title_logo');
  }

  logEventForAddJob() {
    logEvent(this.analytics, 'click_title_addajob');
  }

  logEventForManageJobs() {
    logEvent(this.analytics, 'click_title_managejobs');
  }

  logEventForTalent() {
    logEvent(this.analytics, 'click_title_talent');
  }

  logEventForTimesheets() {
    logEvent(this.analytics, 'click_title_timesheets');
  }

  logEventForCommunity() {
    logEvent(this.analytics, 'click_title_community');
  }

  logEventForChat() {
    logEvent(this.analytics, 'click_title_chat');
  }

  logEventForHelp() {
    logEvent(this.analytics, 'click_title_help');
  }

  logEventForProfile() {
    logEvent(this.analytics, 'click_title_profile');
  }

  ngOnDestroy() {
    this.closeModal();
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
