import dayjs from 'dayjs';
import { DateFormatConstants} from '../utils/date'
import * as uuid from 'uuid';
import { ClubType } from '.';

export enum AvailabilityType
{
  NotSet = -1,
  AvailableAllDay = 0,
  AvailableAM = 1,
  AvailablePM = 2,
  BookedAllDay = 3,
  BookedAM = 4,
  BookedPM = 5,
  AvailableAM_BookedPM = 6,
  BookedAM_AvailablePM = 7,
  Unavailable = 8,
  BookedAllDayCurrentClient = 9,
  BookedAMCurrentClient = 10,
  BookedPMCurrentClient = 11,
  AvailableAM_BookedPMCurrentClient = 12,
  BookedAMCurrentClient_AvailablePM = 13,
  BookedAMCurrentClient_BookedPM = 14,
  BookedAM_BookedPMCurrentClient = 15
}
export function getAvailabilityTypeText(availabilityType: AvailabilityType): string {
  switch (availabilityType) {
    case AvailabilityType.NotSet:
      return 'Availability not set';
    case AvailabilityType.AvailableAllDay:
      return 'Available';
    case AvailabilityType.AvailableAM:
      return 'Available AM';
    case AvailabilityType.AvailablePM:
      return 'Available PM';
    case AvailabilityType.BookedAllDay:
      return 'Unavailable';
    case AvailabilityType.BookedAM:
      return 'Unavailable AM';
    case AvailabilityType.BookedPM:
      return 'Unavailable PM';
    case AvailabilityType.AvailableAM_BookedPM:
      return 'Available AM, Unavailable PM';
    case AvailabilityType.BookedAM_AvailablePM:
      return 'Unavailable AM, Available PM';
    case AvailabilityType.Unavailable:
      return 'Unavailable';
    case AvailabilityType.BookedAllDayCurrentClient:
      return 'Booked (at your school)';
    case AvailabilityType.BookedAMCurrentClient:
      return 'Booked AM (at your school)';
    case AvailabilityType.BookedPMCurrentClient:
      return 'Booked PM (at your school)';
    case AvailabilityType.AvailableAM_BookedPMCurrentClient:
      return 'Available AM, Booked PM (at your school)';
    case AvailabilityType.BookedAMCurrentClient_AvailablePM:
      return 'Booked AM (at your school), Available PM';
    case AvailabilityType.BookedAMCurrentClient_BookedPM:
      return 'Booked AM (at your school), Unavailable PM';
    case AvailabilityType.BookedAM_BookedPMCurrentClient:
      return 'Unavailable AM, Booked PM (at your school)';
    default:
      return 'Availability not set';
  }
}
export enum TermAvailabilityType {
  Unavailable = -1,
  BookedAtClient = 0,
  Available = 1
}

export enum DayType {
  FullDay = 0,
  AM = 1,
  PM = 2
}

const HalfDays = 
[
  AvailabilityType.AvailableAM,
  AvailabilityType.AvailablePM, 
  AvailabilityType.AvailableAM_BookedPM,
  AvailabilityType.BookedAM_AvailablePM,
  AvailabilityType.AvailableAM_BookedPMCurrentClient,
  AvailabilityType.BookedAMCurrentClient_AvailablePM
];

const AvailableDays = 
[
  AvailabilityType.AvailableAllDay, 
  AvailabilityType.AvailableAM, 
  AvailabilityType.AvailablePM,
  AvailabilityType.AvailableAM_BookedPM,
  AvailabilityType.AvailableAM_BookedPMCurrentClient,
  AvailabilityType.BookedAMCurrentClient_AvailablePM,
  AvailabilityType.BookedAM_AvailablePM
];

const BookableDailySupply = 
[
  AvailabilityType.NotSet,
  AvailabilityType.AvailableAllDay, 
  AvailabilityType.AvailableAM, 
  AvailabilityType.AvailablePM,
  AvailabilityType.AvailableAM_BookedPM,
  AvailabilityType.AvailableAM_BookedPMCurrentClient,
  AvailabilityType.BookedAMCurrentClient_AvailablePM,
  AvailabilityType.BookedAM_AvailablePM
];

const BookableTermlyContract = 
[
  AvailabilityType.NotSet,
  AvailabilityType.AvailableAllDay, 
  AvailabilityType.AvailableAM, 
  AvailabilityType.AvailablePM
];

const AvailableAM = 
[
  AvailabilityType.AvailableAM, 
  AvailabilityType.AvailableAM_BookedPM,
  AvailabilityType.AvailableAM_BookedPMCurrentClient
];

const AvailablePM = 
[
  AvailabilityType.AvailablePM,
  AvailabilityType.BookedAMCurrentClient_AvailablePM,
  AvailabilityType.BookedAM_AvailablePM
];

const BookedCurrentClient = 
[
  AvailabilityType.BookedAllDayCurrentClient,
  AvailabilityType.BookedAMCurrentClient,
  AvailabilityType.BookedPMCurrentClient,
  AvailabilityType.AvailableAM_BookedPMCurrentClient,
  AvailabilityType.BookedAMCurrentClient_AvailablePM,
  AvailabilityType.BookedAMCurrentClient_BookedPM,
  AvailabilityType.BookedAM_BookedPMCurrentClient
];

export class AvailabilityDay {
  Id: string = '';
  Date: dayjs.Dayjs = dayjs();
  AvailabilityType = 0;
  Time: dayjs.Dayjs = dayjs('08:30', 'hh:mm');
  Hours: dayjs.Dayjs = dayjs('02:00', 'hh:mm');
  SelectedType: DayType = DayType.FullDay;
  selected = false;

  set Type(type: AvailabilityType) {
    this.AvailabilityType = type;
  }

  get Type() {
    return this.AvailabilityType;
  }

  get isHalfDay() {
    return HalfDays.includes(this.AvailabilityType as AvailabilityType);
  }

  get isHalfDaySelection() {
    return this.SelectedType === DayType.AM || this.SelectedType === DayType.PM;
  }

  get isAvailable() {
    return AvailableDays.includes(this.AvailabilityType);
  }

  get isBookableDailySupply() {
    return BookableDailySupply.includes(this.AvailabilityType);
  }

  get isBookableTermlyContract() {
    return BookableTermlyContract.includes(this.AvailabilityType);
  }

  get isAvailableAM() {
    return AvailableAM.includes(this.AvailabilityType);
  }

  get isAvailablePM() {
    return AvailablePM.includes(this.AvailabilityType);
  }

  get isBookedAtCurrentClient() {
    return BookedCurrentClient.includes(this.AvailabilityType);
  }

  constructor(
    date: dayjs.Dayjs = dayjs('08:30', 'hh:mm'),
    type: AvailabilityType = AvailabilityType.AvailableAllDay,
    time: dayjs.Dayjs = dayjs('08:30', 'hh:mm')
  ) {
    this.Id = uuid.v4()
    this.Date = date;
    this.AvailabilityType = type;
    this.Time = time;
  }

  // To be called only when selecting dates from talent pool / profile calendar
  // where selecting specific day types is not possible
  setSelectedTypeByAvailability() {
    if (this.isAvailableAM) {
      this.SelectedType = DayType.AM;
    }
    else if (this.isAvailablePM) {
      this.SelectedType = DayType.PM;
    }
    else {
      this.SelectedType = DayType.FullDay;
    }
  }

  setAvailabilityTypeByClubType(clubType: ClubType) {
    switch (clubType) {
      case ClubType.After:
        this.SelectedType = DayType.PM;
        break;
      case ClubType.Mid:
      case ClubType.Before:
      default:
        this.SelectedType = DayType.AM;
        break;
    }
  }

  deserialize(input): any {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (key === 'Date') {
          this[key] = dayjs(input[key], DateFormatConstants.YearMonthDayFormat); // Need to review this asap, it sometimes comes in as string, other times as dayjs
        } else if (typeof input[key] !== 'undefined') {
          this[key] = input[key];
        }
      });
    return this;
  }

  // set the serialization date to T10:33:55.1073301Z utc
  serialize(): any {
    const temp: { Date: any, AvailabilityType: any } = {
      Date: this.Date,
      AvailabilityType: this.SelectedType ? this.SelectedType : 0
    };
    temp.Date = this.Date;
    temp.Date.set({hours: this.Time.get('hours'), minutes: this.Time.get('minutes')});
    temp.Date = temp.Date.format('YYYY-MM-DDTHH:mm:ss').split('+')[0].concat('Z');
    return temp;
  }
}
